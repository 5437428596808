import { FC } from 'react';

import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
} from 'styled-system';

import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';
import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';
import Input from 'modules/Ui/Html/Input';

export interface InputProps extends Omit<FormikFieldProps, 'label'> {
  size?: number;
  radioIsVisible?: boolean;
}

const StyledInput = styled(Input)`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  -webkit-appearance: none;
  border-color: ${(props) => props.theme.colors.gray300};
  color: ${(props) => props.theme.colors.gray0};
  outline: none;
  ${({ radioIsVisible }) => !radioIsVisible && accesibleHide};

  &:after {
    background-color: ${(props) => props.theme.colors.brand500};
    border-radius: 50%;
    content: '';
    display: block;
    height: 14px;
    opacity: 0;
    transition: opacity 0.1s linear;
    width: 14px;
  }

  &:disabled {
    color: ${(props) => props.theme.colors.auxiliary};
    @media (hover: hover) {
      &:hover {
        cursor: text;
      }
    }
    ~ .radio__content {
        color: ${(props) => props.theme.colors.gray400};
    }
  }

  &:focus {
    ~ .radio__content {
      color: ${(props) => props.theme.colors.gray800};
    }
  }

  &:checked {
    &:after {
      opacity: 1;
      transition: opacity 0.1s linear;
      will-change: opacity;
    }

    ~ .radio__content {
      color: ${(props) => props.theme.colors.gray800};
    }

    &:disabled {
      color: ${(props) => props.theme.colors.primary200};
    }

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.brand400};
      will-change: opacity;
    }
  }
  
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.gray800};
    }
  }

  &.error {
    border-color: ${(props) => props.theme.colors.error};
    ~ .radio__content {
      color: ${(props) => props.theme.colors.error};
    }
  }`;

export const RadioInput: FC<InputProps> = ({
  className,
  radioIsVisible = true,
  size = 22,
  ...rest
}) => {
  return (
    <StyledInput
      alignItems="center"
      className={className}
      color={color || 'gray0'}
      display="flex"
      height={`${size}px`}
      justifyContent="center"
      radioIsVisible={radioIsVisible}
      type="radio"
      width={`${size}px`}
      {...rest}
    />
  );
};

StyledInput.defaultProps = {
  alignItems: 'center',
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: '1px',
  justifyContent: 'center',
  margin: '0',
};
