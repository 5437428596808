import { LocationDescriptorObject } from 'history';
import { createAsyncAction, createAction, action } from 'typesafe-actions';

import { Address } from 'modules/Address';
import { BusinessType, RegisteredFrom } from 'modules/Business/models/business';

import ActionTypes from './constants';
import { AuthError } from './models/authError';
import { MarketingData } from './models/marketingData';
import { Scope } from './models/scope';
import { TokenResponse } from './token-response';

export const createLead = createAsyncAction(
  [
    ActionTypes.CREATE_LEAD_REQUEST,
    (
      email: string,
      password: string,
      isBookkeeper: boolean,
      marketingData?: MarketingData,
      registeredFrom?: RegisteredFrom
    ) => ({
      email,
      password,
      isBookkeeper,
      ...marketingData,
      registeredFrom,
    }),
  ],
  ActionTypes.CREATE_LEAD_SUCCESS,
  [ActionTypes.CREATE_LEAD_FAILURE, (error: AuthError) => error]
)();

export const login = createAsyncAction(
  [
    ActionTypes.LOGIN_REQUEST,
    (payload: {
      email: string;
      password: string;
      errorMessage?: string;
      redirectRoute?: string | LocationDescriptorObject;
      recaptcha?: string | null;
    }) => payload,
  ],
  ActionTypes.LOGIN_SUCCESS,
  [ActionTypes.LOGIN_FAILURE, (error: Error) => error]
)();

export const loginToken = (
  token: string,
  redirectRoute?: string | LocationDescriptorObject
) =>
  action(ActionTypes.LOGIN_REQUEST, {
    token,
    ...(!!redirectRoute && { redirectRoute }),
  });

export const logout = createAction(ActionTypes.LOGOUT)();

export interface RegisterBusinessRequestPayload {
  fiscalName: string;
  vatNumber: string;
  phoneNumber?: string;
  address: Address;
  errorMessage?: string;
  electronicDocumentProvider?: string;
  companySize?: string;
  software?: string;
  updateUser?: boolean;
  type?: BusinessType;
  registeredFrom?: RegisteredFrom;
}

export interface RegisterFromLeadRequestPayload {
  email: string;
  password?: string;
  redirectRoute?: string | LocationDescriptorObject;
  isBookkeeper: boolean;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  landing?: string;
  business?: {
    fiscalName: string;
    vatNumber: string;
    phoneNumber?: string;
    address: Address;
  };
  bookkeeper?: {
    fiscalName: string;
    vatNumber: string;
    phoneNumber: string;
    address: Address;
    electronicDocumentProvider?: string;
    companySize?: string;
    software?: string;
    type: BusinessType;
  };
}

export const registerFromLead = createAsyncAction(
  [
    ActionTypes.REGISTER_FROM_LEAD_REQUEST,
    (payload: RegisterFromLeadRequestPayload) => payload,
  ],
  ActionTypes.REGISTER_FROM_LEAD_SUCCESS,
  [ActionTypes.REGISTER_FROM_LEAD_FAILURE, (error: AuthError) => error]
)();

export const loginBusiness = createAsyncAction(
  [ActionTypes.LOGIN_BUSINESS_REQUEST, (payload: string) => payload],
  ActionTypes.LOGIN_BUSINESS_SUCCESS,
  [ActionTypes.LOGIN_BUSINESS_FAILURE, (error: Error) => error]
)();

export const setScopes = createAction(
  ActionTypes.SET_SCOPES,
  (payload: Scope[]) => payload
)();

export const clearRegisterError = createAction(
  ActionTypes.CLEAR_REGISTER_ERROR
)();

export const loginUsingToken = createAction(
  ActionTypes.LOGIN_USING_TOKEN,
  (payload: {
    auth?: TokenResponse['data']['auth'];
    redirectRoute?: string | LocationDescriptorObject;
    email?: string;
  }) => payload
)();
