import { FC, ReactNode } from 'react';

import {
  space,
  position,
  PositionProps,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import Label from 'modules/Ui/Html/Label';

import Text from '../../../Text';
import { RadioInput } from './commons/RadioInput';

export interface RadioButtonProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    BorderProps,
    PositionProps {
  checked?: boolean;
  children?: ReactNode | string;
  className?: string;
  disabled?: boolean;
  id: string;
  margin?: string;
  name?: string;
  height?: string;
  onChange?: (value: any) => void;
  onClick?: () => void;
  onFocus?: () => void;
  radioIsVisible?: boolean;
  required?: boolean;
  size?: number;
  value: any;
  width?: string;
  minWidth?: string;
  error?: string;
}

const StyledLabel = styled(Label)`
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  @media(hover : hover) {
    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    }
  }
`;

const RadioButton: FC<RadioButtonProps> = ({
  checked,
  children,
  className,
  disabled,
  error,
  height,
  radioIsVisible,
  id,
  margin,
  minWidth,
  name,
  onChange,
  onClick,
  required,
  value,
  width,
  size,
  ...rest
}) => {
  return (
    <StyledLabel
      alignItems="center"
      className={className}
      disabled={disabled}
      display="flex"
      htmlFor={id}
      margin={margin}
      onClick={onClick}
      width={width}
      {...rest}
    >
      <RadioInput
        aria-labelledby={`${id}-label`}
        checked={checked}
        className={error ? 'error' : undefined}
        data-testid={id}
        disabled={disabled}
        error={error}
        id={id}
        minWidth={minWidth}
        name={name}
        onChange={onChange}
        radioIsVisible={radioIsVisible}
        required={required}
        size={size}
        value={value}
      />
      <Text
        aria-hidden="true"
        className="radio__content"
        color="gray600"
        flex="1"
        id={`${id}-label`}
        marginLeft={!radioIsVisible ? '8px' : undefined}
        position="relative"
        tag="span"
      >
        {children}
      </Text>
    </StyledLabel>
  );
};

export default RadioButton;
