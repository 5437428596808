import { ApplicationRootState } from 'types';

import { SPANISH, EURO, EURO_SYMBOL } from './constants';

interface Currencies {
  [key: string]: string;
}

const CURRENCIES: Currencies = {
  [SPANISH]: EURO,
};

const CURRENCY_SYMBOLS: Currencies = {
  [SPANISH]: EURO_SYMBOL,
};

export const selectLocale = (state: ApplicationRootState) =>
  state.locale || SPANISH;

export const selectCurrency = (state: ApplicationRootState) =>
  CURRENCIES[state.locale] || EURO;

export const selectCurrencySymbol = (state: ApplicationRootState) =>
  CURRENCY_SYMBOLS[state.locale] || EURO_SYMBOL;
