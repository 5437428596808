import { FC } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Img, Box } from 'modules/Ui';
import IconCheck from 'modules/Ui/Icons/IconCheck';

interface ImageProps {
  active?: boolean;
  template: string;
}

const Wrapper = styled(Box)`
  transition: border-width 0.2s linear, border-color 0.2s linear;
`;

const TemplateButtonImage: FC<ImageProps> = ({ active, template }) => {
  const theme = useTheme();
  return (
    <>
      <Wrapper
        borderColor={active ? 'brand400' : 'gray300'}
        borderRadius="12px"
        borderStyle="solid"
        borderWidth={active ? '3px' : '1px'}
        boxSizing="border-box"
        className="template__button--wrapper"
        display="block"
        height={{ _: 111, sm: '160px', md: '120px' }}
        marginBottom="12px"
        overflow="hidden"
        tag="span"
        width="100%"
      >
        <Img height="100%" width="100%" ariaHidden src={template} />
      </Wrapper>
      <Box
        className="template__button--check"
        alignItems="center"
        backgroundColor="brand400"
        border={`2px solid ${theme.colors.gray0}`}
        borderRadius="50%"
        bottom="16px"
        boxSizing="border-box"
        display="flex"
        fadeIn
        height="32px"
        justifyContent="center"
        position="absolute"
        right="-10px"
        tag="span"
        width="32px"
      >
        <IconCheck size={16} color="gray0" />
      </Box>
    </>
  );
};

export default TemplateButtonImage;
