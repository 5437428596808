import { FC } from 'react';

import omit from 'lodash/omit';

import useTheme from 'modules/Theme/hooks/useTheme';
import Box from 'modules/Ui/Box';
import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';
import IconStateDone from 'modules/Ui/Icons/IconStateDone';

import Button from '../Button';
import Description from '../Commons/Description';
import Input from '../Commons/Input';
import InputWrapper from '../Commons/InputWrapper';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';

export interface FieldProps extends FormikFieldProps {
  autoFocus?: boolean;
}

const Field: FC<FieldProps> = ({
  autoFocus,
  borderColor,
  disabled,
  disabledWithAsterisk,
  error,
  gridArea,
  gridColumn,
  helpText,
  helpTextAlign = 'left',
  icon,
  iconAriaLabel,
  iconColor,
  iconOnClick,
  iconPosition = 'end',
  id,
  info,
  infoDelay,
  infoPlace,
  label,
  labelHidden,
  maxLength,
  noDescription,
  required,
  requiredWithoutAsterisk,
  success,
  textAlign,
  type,
  width,
  ...rest
}) => {
  const hasIcon = !!icon || !!success;
  const labelRestProps = omit(rest, [
    'data-testid',
    'onBlur',
    'onChange',
    'onPaste',
    'onKeyDown',
    'onFocus',
  ]);
  const theme = useTheme();
  return (
    <Label
      gridArea={gridArea}
      gridColumn={gridColumn}
      id={id}
      info={info}
      width={width}
      {...{
        ...labelRestProps,
      }}
    >
      <LabelText
        aria-hidden={info ? true : undefined}
        disabled={disabled}
        disabledWithAsterisk={disabledWithAsterisk}
        hidden={labelHidden}
        id={`label-${id}`}
        info={info}
        infoDelay={infoDelay}
        infoPlace={infoPlace}
        required={required}
        requiredWithoutAsterisk={requiredWithoutAsterisk}
        textAlign={textAlign}
      >
        {label}
      </LabelText>
      <InputWrapper
        {...{
          borderColor,
          hasIcon,
          iconColor,
          error,
          disabled,
          iconPosition,
        }}
      >
        <Input
          gridColumnStart={iconPosition === 'start' ? '2' : ''}
          {...{
            hasIcon,
            iconPosition,
            autoFocus: !!autoFocus,
            disabled,
            error,
            helpText,
            id,
            maxLength,
            type,
            textAlign,
            onWheel: (e) => {
              (e.target as HTMLInputElement).blur();
            },
            ...rest,
          }}
          required={required || requiredWithoutAsterisk ? true : undefined}
        />
        {!error && !!success && <IconStateDone size={15} />}
        {iconOnClick ? (
          <Button
            aria-label={iconAriaLabel}
            color={theme.colors.brand500}
            gridArea={iconPosition === 'start' ? '1 / 1 / 3 / 2' : undefined}
            onClick={iconOnClick}
          >
            {icon}
          </Button>
        ) : (
          <Box
            color={iconColor || 'primary300'}
            display="flex"
            gridArea={iconPosition === 'start' ? '1 / 1 / 3 / 2' : undefined}
            placeContent="center"
            tag="span"
          >
            {icon}
          </Box>
        )}
      </InputWrapper>
      <Description
        hasError={!!error}
        hide={noDescription}
        id={`description-${id}`}
        data-testid={`description-${id}`}
        isSuccess={!!success}
        textAlign={helpTextAlign}
      >
        {error ?? helpText ?? success}
      </Description>
    </Label>
  );
};

export default Field;
