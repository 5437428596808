import { FC } from 'react';

import { useField } from 'formik';
import styled from 'styled-components';

import { TemplateLanguages } from 'modules/I18n/types/templateLanguages';
import { Text } from 'modules/Ui';
import RadioButtonFormik from 'modules/Ui/Formik/RadioButtonFormik';

import { SOURCE_SERIF_PRO } from '../constants';
import { TemplateType } from '../types';
import ButtonImage from './TemplateButtonImage';

interface TemplateButtonProps {
  id: string;
  text: string;
  template: string;
  value: string;
}

const StyledTemplateButton = styled(RadioButtonFormik)`
  .template__button--check {
    display: none;
  }

  .radio__content {
    margin-left: 0;
  }

  input {
    &:checked ~ .radio__content .template__button--check {
      display: flex;
    }
  }
`;

const TemplateButton: FC<TemplateButtonProps> = ({
  id,
  template,
  text,
  value,
}) => {
  const [field] = useField('templateType');
  const [languageField, , langHelpers] = useField('language');
  const [, typographyMeta, typograpHelpers] = useField('typography');
  const handleButtonChange = (val: string) => {
    val === TemplateType.CLASSIC &&
      languageField.value === TemplateLanguages.EUES &&
      langHelpers.setValue(TemplateLanguages.ES);
    val === TemplateType.CLASSIC &&
      !typographyMeta.touched &&
      typograpHelpers.setValue(SOURCE_SERIF_PRO);
  };

  const active = field.value === value;
  return (
    <StyledTemplateButton
      id={id}
      name="templateType"
      marginRight="6px"
      value={value}
      onChange={handleButtonChange}
      radioIsVisible={false}
    >
      <ButtonImage {...{ active, template }} />
      <Text
        color={active ? 'brand400' : 'gray600'}
        display="block"
        fontSize={14}
        lineHeight={16}
        tag="span"
        textAlign="center"
      >
        {text}
      </Text>
    </StyledTemplateButton>
  );
};

export default TemplateButton;
