import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

const texts: DefineMessages = defineMessages({
  radioPriceTitle: { id: 'gofre.Subscriptions.Plan.radioPriceTitle' },
  allPlans: { id: 'gofre.Subscriptions.Plan.allPlans' },
  allPlansIncludes: { id: 'gofre.Subscriptions.Plans.allPlansIncludes' },
  annual: { id: 'gofre.Subscriptions.Plan.annual' },
  basic: { id: 'gofre.Subscriptions.Plan.basic' },
  basicDesc: { id: 'gofre.Subscriptions.Plan.basicDesc' },
  basicItem1: { id: 'gofre.Subscriptions.Plan.basic.item1' },
  basicItem2: { id: 'gofre.Subscriptions.Plan.basic.item2' },
  basicItem3: { id: 'gofre.Subscriptions.Plan.basic.item3' },
  ocr: { id: 'gofre.Subscriptions.Plan.basic.ocr' },
  ocrPro: { id: 'gofre.Subscriptions.Plan.pro.ocr' },
  ocrUnlimited: { id: 'gofre.Subscriptions.Plan.unlimited.ocr' },
  basicItem4: { id: 'gofre.Subscriptions.Plan.basic.item4' },
  itemBasicSupport: { id: 'gofre.Subscriptions.Plan.item.basic.support' },
  change: { id: 'gofre.Subscriptions.Plan.change' },
  faq: { id: 'gofre.Subscriptions.Plan.faq' },
  text: { id: 'gofre.Subscriptions.Plan.text' },
  text02: { id: 'gofre.Subscriptions.Plan.text02' },
  pro: { id: 'gofre.Subscriptions.Plan.pro' },
  proDesc: { id: 'gofre.Subscriptions.Plan.proDesc' },
  itemRecurrent: { id: 'gofre.Subscriptions.Plan.items.recurrent' },
  expensesRecurrent: {
    id: 'gofre.Subscriptions.Plan.items.expenses.recurrent',
  },
  proItem1: { id: 'gofre.Subscriptions.Plan.pro.item1' },
  proItem2: { id: 'gofre.Subscriptions.Plan.pro.item2' },
  proItem3: { id: 'gofre.Subscriptions.Plan.pro.item3' },
  proPaymentReminder: { id: 'gofre.Subscriptions.Plan.pro.proPaymentReminder' },
  proItem4: { id: 'gofre.Subscriptions.Plan.pro.item4' },
  proItem5: { id: 'gofre.Subscriptions.Plan.pro.item5' },
  customerPanel: { id: 'gofre.Subscriptions.Plan.pro.customerPanel' },
  recurrentsItem: { id: 'gofre.Subscriptions.Plan.items.recurrent' },
  publicAPI: { id: 'gofre.Subscriptions.Plan.pro.publicAPI' },
  radioAnnual: { id: 'gofre.Subscriptions.Plan.radioAnnual' },
  radioMonthly: { id: 'gofre.Subscriptions.Plan.radioMonthly' },
  sub: { id: 'gofre.Subscriptions.Plan.sub' },
  choosePlanTitle: { id: 'gofre.Subscriptions.Plan.title-choose-plan' },
  unlimited: { id: 'gofre.Subscriptions.Plan.unlimited' },
  unlimitedDesc: { id: 'gofre.Subscriptions.Plan.unlimitedDesc' },
  unlimitedItem1: { id: 'gofre.Subscriptions.Plan.unlimited.item1' },
  unlimitedItem2: { id: 'gofre.Subscriptions.Plan.unlimited.item2' },
  unlimitedItem3: { id: 'gofre.Subscriptions.Plan.unlimited.item3' },
  unlimitedItem4: { id: 'gofre.Subscriptions.Plan.unlimited.item4' },
  unlimitedPaymentReminder: {
    id: 'gofre.Subscriptions.Plan.pro.unlimitedPaymentReminder',
  },
  unlimitedItem5: { id: 'gofre.Subscriptions.Plan.unlimited.item5' },
  unlimitedItem6: { id: 'gofre.Subscriptions.Plan.unlimited.item6' },
  unlimitedItem7: { id: 'gofre.Subscriptions.Plan.unlimited.item7' },
  error: { id: 'gofre.Subscriptions.Plan.error' },
  trialEndTitle: { id: 'gofre.Subscriptions.Plan.title-trial-end' },
  closeSession: { id: 'gofre.Subscriptions.closeSession' },
  plansSelect: { id: 'gofre.Subscriptions.Plan.markdown' },
  customerSupport: { id: 'gofre.Subscriptions.Plans.customerSupport' },
  customerSupportDesc: { id: 'gofre.Subscriptions.Plans.customerSupport.desc' },
  mobile: { id: 'gofre.Subscriptions.Plans.mobile' },
  mobileDesc: { id: 'gofre.Subscriptions.Plans.mobile.desc' },
  products: { id: 'gofre.Subscriptions.Plans.products' },
  productsDesc: { id: 'gofre.Subscriptions.Plans.products.desc' },
  expenses: { id: 'gofre.Subscriptions.Plans.expenses' },
  expensesDesc: { id: 'gofre.Subscriptions.Plans.expenses.desc' },
  accountant: { id: 'gofre.Subscriptions.Plans.accountant' },
  accountantDesc: { id: 'gofre.Subscriptions.Plans.accountant.desc' },
  security: { id: 'gofre.Subscriptions.Plans.security' },
  securityDesc: { id: 'gofre.Subscriptions.Plans.security.desc' },
  invoices: { id: 'gofre.Subscriptions.Plans.invoices' },
  invoicesDesc: { id: 'gofre.Subscriptions.Plans.invoices.desc' },
  tbai: { id: 'gofre.Subscriptions.Plans.tbai' },
  tbaiDesc: { id: 'gofre.Subscriptions.Plans.tbai.desc' },
  agenda: { id: 'gofre.Subscriptions.Plans.agenda' },
  agendaDesc: { id: 'gofre.Subscriptions.Plans.agenda.desc' },
  productStock: { id: 'gofre.Subscriptions.Plans.pro.productStock' },
});
export default texts;

export const subscriptionCancelled: DefineMessages = defineMessages({
  title: { id: 'gofre.Subscriptions.Cancelled.title' },
  heading: { id: 'gofre.Subscriptions.Cancelled.heading' },
  heading2: { id: 'gofre.Subscriptions.Cancelled.heading2' },
  sub: { id: 'gofre.Subscriptions.Cancelled.sub' },
});

export const discountBanner: DefineMessages = defineMessages({
  text: { id: 'gofre.Subscriptions.discountBanner.Text' },
  addBookkeeperText: {
    id: 'gofre.Subscriptions.discountBanner.AddBookkeeperText',
  },
  bookkeeperDiscountText: {
    id: 'gofre.Subscriptions.discountBanner.BookkeeperDiscountText',
  },
  pill: { id: 'gofre.Subscriptions.discountBanner.Pill' },
});

export const advantages: DefineMessages = defineMessages({
  saveTime: { id: 'gofre.Subscriptions.Plans.saveTime' },
  saveTimeDesc: { id: 'gofre.Subscriptions.Plans.saveTime.desc' },
  flexible: { id: 'gofre.Subscriptions.Plans.flexible' },
  flexibleDesc: { id: 'gofre.Subscriptions.Plans.flexible.desc' },
  support: { id: 'gofre.Subscriptions.Plans.support' },
  supportDesc: { id: 'gofre.Subscriptions.Plans.support.desc' },
});

export const plans: DefineMessages = defineMessages({
  active: { id: 'gofre.Ui.SubscriptionPlan.active' },
  button: { id: 'gofre.Ui.SubscriptionPlan.button' },
  buttonActive: { id: 'gofre.Ui.SubscriptionPlan.buttonActive' },
  monthlyTaxes: { id: 'gofre.Ui.SubscriptionPlan.monthlyTaxes' },
  yearlyTaxes: { id: 'gofre.Ui.SubscriptionPlan.yearlyTaxes' },
  popular: { id: 'gofre.Ui.SubscriptionPlan.popular' },
  addCountryTax: { id: 'gofre.Ui.SubscriptionPlan.addCountryTax' },
  campaign: { id: 'gofre.Ui.SubscriptionPlan.popular-campaign' },
});

export const subscriptionCancelledText = defineMessages({
  title: { id: 'gofre.subscriptions.cancelled.title' },
  pausedTitle: { id: 'gofre.subscriptions.paused-title' },
});

export const subscriptionCancelledVodafone = defineMessages({
  title: { id: 'gofre.Subscriptions.vodafoneCancelled.title' },
  pausedTitle: { id: 'gofre.Subscriptions.vodafoneCancelled.paused-title' },
  description: { id: 'gofre.Subscriptions.vodafoneCancelled.description' },
});

export const subscriptionCancelledTelefonica = defineMessages({
  title: { id: 'gofre.Subscriptions.telefonicaCancelled.title' },
  pausedTitle: { id: 'gofre.Subscriptions.telefonicaCancelled.paused-title' },
  description: { id: 'gofre.Subscriptions.telefonicaCancelled.description' },
});

export const subscriptionCancelledAutonomosTelefonica = defineMessages({
  title: { id: 'gofre.Subscriptions.telefonicaCancelled.title' },
  pausedTitle: { id: 'gofre.Subscriptions.telefonicaCancelled.paused-title' },
  description: {
    id: 'gofre.Subscriptions.AutonomosTelefonicaCanceled.description',
  },
});

export const subscriptionCancelledDelSol = defineMessages({
  description: { id: 'gofre.Subscriptions.delsolCancelled.description' },
});

export const subscriptionCancelledAplifisa = defineMessages({
  description: { id: 'gofre.Subscriptions.aplifisaCancelled.description' },
});

export const subscriptionCancelledConversia = defineMessages({
  description: { id: 'gofre.Subscriptions.conversiaCancelled.description' },
});

export const subscriptionCancelledGeneric = defineMessages({
  description: { id: 'gofre.Subscriptions.genericCancelled.description' },
});
