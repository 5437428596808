import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import { useFormikContext } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  PaymentMethod,
  PaymentMethodType,
} from 'modules/PaymentMethods/models/paymentMethods';
import { Box } from 'modules/Ui';
import RadioButtonCardFormik from 'modules/Ui/Formik/RadioButtonCardFormik';

import { getSubForm } from './paymentMethodItems';
import { PaymentMethodItem } from './types';

interface Props {
  items: PaymentMethodItem[];
  selectedPaymentType?: PaymentMethodType;
  onChange?: (value: any) => void;
  hasBizum: boolean;
  hasCash: boolean;
  hasCashOnDelivery: boolean;
  hasDirect: boolean;
  hasOther: boolean;
  forContact?: boolean;
}

const RadioButtonRow: FC<Props> = ({
  onChange,
  items,
  selectedPaymentType,
  hasBizum,
  hasCash,
  hasCashOnDelivery,
  hasDirect,
  hasOther,
  forContact,
}) => {
  const Subform = getSubForm(items, selectedPaymentType);
  const { t } = useTranslations();
  const { values } = useFormikContext<PaymentMethod>();

  function isDisabled(type: PaymentMethodType) {
    switch (type) {
      case PaymentMethodType.TRANSFER:
        return false;
      case PaymentMethodType.BIZUM:
        return hasBizum;
      case PaymentMethodType.CASH_ON_DELIVERY:
        return hasCashOnDelivery;
      case PaymentMethodType.OTHER:
        return hasOther;
      case PaymentMethodType.DIRECT_DEBIT:
        return hasDirect;
      default:
        return hasCash;
    }
  }

  return (
    <Box
      aria-atomic="false"
      aria-labelledby="fieldset-payment-methods"
      aria-live="polite"
      aria-relevant="additions"
      display={{ sm: 'flex' }}
      flexWrap={{ sm: 'wrap' }}
      justifyContent={{ sm: 'space-between' }}
      marginBottom={{ _: '8px', sm: '16px' }}
      role="radiogroup"
    >
      {items.map(({ type, label }) => {
        return (
          <>
            <RadioButtonCardFormik
              key={type}
              disabled={isDisabled(type)}
              id={type}
              name="type"
              marginBottom={{ _: '8px', sm: 'initial' }}
              text={t(label)}
              value={type}
              width={{
                _: '100%',
                sm: 'calc(33.33% - 8px)',
              }}
              {...{ onChange }}
            />
            {type === values.type && Subform && (
              <Visible xs>
                <Subform isEditing={false} />
              </Visible>
            )}
          </>
        );
      })}
      {Subform && (
        <Hidden xs>
          <Box padding="8px 0" width="100%">
            <Subform isEditing={false} forContact={forContact} />
          </Box>
        </Hidden>
      )}
    </Box>
  );
};

export default RadioButtonRow;
