import { FC, ChangeEvent, useCallback } from 'react';

import { useField } from 'formik';

import RadioButton from '../Form/RadioGroup/RadioButton';
import { RadioButtonProps } from '../Form/RadioGroup/RadioButton/RadioButton';
import useFocusHandler from './useFocusHandler';

const RadioButtonFormik: FC<RadioButtonProps> = ({
  size,
  onChange,
  radioIsVisible,
  required,
  name,
  children,
  id,
  ...props
}) => {
  const [field, meta, helpers] = useField(name || '');
  const onChangeHandler = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(value);
      helpers.setTouched(true);
      return helpers.setValue(value);
    },
    [helpers, onChange]
  );

  const onFocusHandler = useFocusHandler(name || id);
  return (
    <RadioButton
      {...field}
      size={size}
      id={id}
      required={required}
      radioIsVisible={radioIsVisible}
      checked={meta.value === props.value}
      error={meta.error ? meta.error : undefined}
      {...props}
      onChange={onChangeHandler}
      onFocus={onFocusHandler}
    >
      {children}
    </RadioButton>
  );
};
export default RadioButtonFormik;
