import styled from 'styled-components';
import {
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';

import Box from 'modules/Ui/Box';

import Text from '../../Text';
import { RadioInput } from '../RadioGroup/RadioButton/commons';

export interface RadioButtonCardProps
  extends FlexboxProps,
    LayoutProps,
    SpaceProps,
    ColorProps,
    TypographyProps {
  checked?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  error?: string;
  id: string;
  name: string;
  text: string;
  onChange?: (value: any) => void;
  onFocus?: (event: any) => void;
  onClick?: (value: any) => void;
  innerPadding?: string;
  required?: boolean;
  value: any;
  radioIsVisible?: boolean;
  textIsVisible?: boolean;
}

const StyledText = styled(Text)``;
const StyledWrapper = styled(Box)<RadioButtonCardProps>`
  &:focus-within {
    border-color: ${(props) => `${props.theme.colors.brand500}`};
  }

  ${({ checked, theme }) =>
    checked && `border-color: ${theme.colors.brand500};`}

  @media (hover: hover) {
    &:hover {
      border-color: ${({ checked, isDisabled, theme }) => {
        if (isDisabled) return theme.colors.gray300;
        if (checked) return theme.colors.brand500;
        return theme.colors.gray600;
      }};
      cursor: ${({ isDisabled }) => (isDisabled ? 'text' : 'pointer')};

    }
  }

  input:checked {
    ~ ${StyledText} {
      color: ${(props) => props.theme.colors.brand500};
    }
  }
`;

const RadioButtonCard = (props: RadioButtonCardProps) => {
  const {
    checked,
    children,
    color,
    disabled,
    error,
    fontSize,
    id,
    innerPadding,
    lineHeight,
    height,
    name,
    onChange,
    onClick,
    radioIsVisible = true,
    required,
    text,
    width,
    textIsVisible = true,
    value,
    ...rest
  } = props;
  const defaultColor = disabled ? 'gray400' : 'gray600';
  const gridSystem = () => {
    if (radioIsVisible && textIsVisible) {
      if (children) {
        return '22px 1fr minmax(24px, auto)';
      }
      return '22px 1fr';
    }
    return '';
  };

  return (
    <StyledWrapper
      alignItems={textIsVisible ? 'center' : ''}
      backgroundColor={disabled ? 'auxiliary' : 'gray0'}
      borderColor={error ? 'error' : 'gray300'}
      borderRadius="8px"
      borderStyle="solid"
      borderWidth="1px"
      boxSizing={textIsVisible ? 'border-box' : ''}
      checked={checked}
      className="radioCard"
      columnGap={textIsVisible ? '8px' : ''}
      disabled={disabled}
      display={textIsVisible ? 'grid' : undefined}
      gridTemplateColumns={gridSystem()}
      height={height || '72px'}
      htmlFor={id}
      innerPadding={innerPadding}
      onClick={onClick}
      padding={innerPadding || { _: '0 14px', sm: '0 24px' }}
      tag="label"
      width={width}
      isDisabled={disabled}
      {...rest}
    >
      <RadioInput
        radioIsVisible={radioIsVisible}
        aria-invalid={error ? true : undefined}
        aria-labelledby={`${id}-label`}
        checked={checked}
        data-testid={`${id}-radio`}
        disabled={disabled}
        error={error}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        value={value}
      />
      {textIsVisible ? (
        <StyledText
          aria-hidden="true"
          className="radioCard-text"
          color={error ? 'error' : defaultColor}
          fontSize={fontSize}
          fontWeight="600"
          id={`${id}-label`}
          lineHeight={lineHeight}
          tag="span"
        >
          {text}
        </StyledText>
      ) : (
        <Box tag="span" accessibleHidden>
          {text}
        </Box>
      )}
      {children}
    </StyledWrapper>
  );
};

export default RadioButtonCard;
