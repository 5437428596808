enum ActionTypes {
  SET_LOCALE = 'gofre/app/SET_LOCALE',
}

export const SPANISH = 'es-ES';

export const EURO = 'EUR';
export const EURO_SYMBOL = '€';

export default ActionTypes;
