import { FC, ReactElement, ReactNode } from 'react';

import {
  gridColumn,
  GridColumnProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  system,
} from 'styled-system';

import { shadowHover } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';
import { RadioInput } from 'modules/Ui/Form/RadioGroup/RadioButton/commons';
import { RadioButtonProps } from 'modules/Ui/Form/RadioGroup/RadioButton/RadioButton';
import { TextProps } from 'modules/Ui/Text/Text';

interface ListDesktopItemProps
  extends RadioButtonProps,
    GridColumnProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps {
  column1?: string | JSX.Element;
  column2?: string | JSX.Element;
  column3?: string | JSX.Element;
  column4?: string | JSX.Element;
  onChange?(): void;
  gap?: {} | string;
  columnGap?: {} | string;
  rowGap?: {} | string;
  placeSelf?: {} | string;
  resetStyles?: boolean;
  radioIsVisible?: boolean;
}

export const StyledRadio = styled(Box)`
${(props) =>
  shadowHover({
    offsetBlur: '1px 20px 6px -15px',
    color: props.theme.colors.gray300,
    opacity: 80,
  })};

  ${gridColumn}
  ${gridTemplateColumns}
  ${gridTemplateRows}

  ${system({
    alignContent: {
      property: 'alignContent',
      transform: (value) => `${value}`,
    },
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    rowGap: {
      property: 'rowGap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
    placeSelf: {
      property: 'placeSelf',
      scale: 'placeSelf',
    },
  })}
  `;

type Column = string | ReactElement<TextProps> | undefined;

function renderColumn(column: Column, TextComponent: JSX.Element): ReactNode {
  if (typeof column === 'string') {
    return TextComponent;
  }
  return <Box tag="span">{column}</Box>;
}

const ListDesktopItem: FC<ListDesktopItemProps> = ({
  id,
  value,
  onChange,
  checked,
  className,
  column1,
  column2,
  column3,
  column4,
  resetStyles = false,
  name,
  ...rest
}) => {
  const paintDefaultStyles = (column2 || column3) && !resetStyles;
  return (
    <StyledRadio
      borderBottomColor="gray200"
      className="list-desktop-item"
      display="flex"
      htmlFor={id}
      tag="label"
      {...rest}
    >
      <RadioInput
        checked={checked}
        className={className}
        id={id}
        name="searchedProduct"
        onChange={onChange}
        size={26}
        value={value}
      />
      <Box alignItems="center" className="radio__content" tag="span">
        {renderColumn(
          column1,
          <Text
            hasEllipsis
            marginLeft={resetStyles ? undefined : '16px'}
            tag="span"
            textSize="xs"
            width={paintDefaultStyles ? '99px' : undefined}
          >
            {column1}
          </Text>
        )}
        {renderColumn(
          column2,
          <Text
            color="gray800"
            hasEllipsis
            margin={resetStyles ? undefined : '0 16px'}
            tag="span"
            textSize="xs"
          >
            {column2}
          </Text>
        )}
        {renderColumn(
          column3,
          <Text
            flex={resetStyles ? undefined : '1'}
            color="gray800"
            fontWeight="600"
            tag="span"
            textAlign="right"
            textSize="xs"
          >
            {column3}
          </Text>
        )}
        {renderColumn(
          column4,
          <Text
            flex={resetStyles ? undefined : '1'}
            color="gray800"
            fontWeight="600"
            tag="span"
            textAlign="right"
            textSize="xs"
          >
            {column4}
          </Text>
        )}
      </Box>
    </StyledRadio>
  );
};

StyledRadio.defaultProps = {
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  padding: '16px',
  position: 'relative',
};

export default ListDesktopItem;
